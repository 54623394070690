<div class="page">
    <app-header></app-header>
    <!--Banner-->
    <div class="banner">
        <div class="container">


            <div class="banner-content" style="display: flex;justify-content: space-around;">
                <div style="width:50%;">
                    <h1>Build <br> exclusively <br>for your gym</h1>
                    <p>Innovative fitness management software, helping growing businesses retain & scale revenue</p>
                    <button mat-flat-button>Request a Demo</button>
                </div>
                <div style="width:50%;">
                    <img src="assets/images/website/image1.png" width="100%" />
                </div>
            </div>

        </div>
    </div>
    <!--Our Solutions-->
    <div class="solutions">
        <div class="container">
            <h1 class="title">Our Solutions</h1>
            <h4 class="sub-title">Reclaim your time, grow finacially, be confident</h4>
            <mat-tab-group>
                <mat-tab label="Manage in One Place">
                    <div class="tab-content">
                        <div fxLayout="row wrap">
                            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                                class="about-content">
                                <div class="wow bounceInLeft ">
                                    <h5>Manage your Operations <br>from one place</h5>
                                    <p>Whether you are listing your Classes, Class-packs, Appointments, Membership,
                                        Events or Facilities - all these is possible with Petalyellow. </p>
                                </div>
                            </div>
                            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                                class="wow bounceInRight">
                                <img src="assets/images/website/image2.png" width="100%" height="100%" />
                            </div>

                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Drive Customer Engagement">
                    <div class="tab-content">
                        <div fxLayout="row wrap">
                            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                                class="about-content">
                                <div class="wow bounceInLeft ">
                                    <h5>Manage your Operations <br>from one place</h5>
                                    <p>Whether you are listing your Classes, Class-packs, Appointments, Membership,
                                        Events or Facilities - all these is possible with Petalyellow. </p>
                                </div>
                            </div>
                            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                                class="wow bounceInRight">
                                <img src="assets/images/website/image3.png" width="100%" height="100%" />
                            </div>

                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Community Building">
                    <div class="tab-content">
                        <div fxLayout="row wrap">
                            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                                class="about-content">
                                <div class="wow bounceInLeft ">
                                    <h5>Manage your Operations <br>from one place</h5>
                                    <p>Whether you are listing your Classes, Class-packs, Appointments, Membership,
                                        Events or Facilities - all these is possible with Petalyellow. </p>
                                </div>
                            </div>
                            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                                class="wow bounceInRight">
                                <img src="assets/images/website/image4.png" width="100%" height="100%" />
                            </div>

                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <!--Most important features-->
    <div class="features">
        <div fxLayout="row wrap" class="row">
            <div fxFlex="100" fxFlex.xl="25" fxFlex.lg="25" fxFlex.sm="25" fxFlex.md="25" fxFlex.xs="15"
                class="wow bounceInLeft">
                <img src="assets/images/dot.png" width="40%" />
            </div>
            <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="70"
                class="text-center">
                <h1 class="title">Most Important Features</h1>
                <h4 class="sub-title">Most recommended platform for sports facilities</h4>
            </div>
            <div fxFlex="100" fxFlex.xl="25" fxFlex.lg="25" fxFlex.sm="25" fxFlex.md="25" fxFlex.xs="15"
                class="text-right wow bounceInRight">
                <img src="assets/images/dot.png" width="40%" />
            </div>
        </div>
        <div class="container">
            <!-- <img src="assets/images/dashboard.png" width="100%" class="mt-30" /> -->
            <div fxLayout="row wrap" class="row">
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInLeft feature">
                    <h5>Smart Attendance</h5>
                    <p>Members / employees can enter daily attendance by using the RFID card or Bio-matric.
                        Its helps to monitor the members/employees entry and exit timings.</p>
                </div>
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInRight feature img-sec">
                    <img src="assets/images/website/image3.png" width="100%" class="mt-30" />
                </div>
            </div>
            <div fxLayout="row wrap" class="row reverse">
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInLeft feature img-sec">
                    <img src="assets/images/website/image4.png" width="100%" class="mt-30" />
                </div>
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInRight feature">
                    <h5>Valet Parking</h5>
                    <p>It provides the facilities on demand of valets who park and at the end retrieve the vehicle
                        on the request of the owner. While the parking as well as retrieving vehicles, a notification
                        can be send to the member.
                    </p>
                </div>
            </div>
            <div fxLayout="row wrap" class="row">
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInLeft feature">
                    <h5>Heart Rate Band</h5>
                    <p>It helps to monitor the heart rate and calories updates by utilizing the Heart Rate Band, It can
                        accurately detect the heart rate during the workout time and notify.
                    </p>
                </div>
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInRight feature img-sec">
                    <img src="assets/images/website/image5.png" width="100%" class="mt-30" />
                </div>
            </div>
            <div fxLayout="row wrap" class="row reverse">
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInLeft feature img-sec">
                    <img src="assets/images/website/image6.png" width="100%" class="mt-30" />
                </div>
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInRight feature">
                    <h5>Fitness Consultation</h5>
                    <p>On the availability of consultant, the member can get the appointment to visit the diet and
                        physiotherapist.It helps to know about their
                        fitness level and push to move on their fitness goal.</p>
                </div>
            </div>
            <div fxLayout="row wrap" class="row">
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInLeft feature">
                    <h5>Account Management</h5>
                    <p>Easily can manage the member's fees based on the package selection.</p>
                </div>
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInRight feature img-sec">
                    <img src="assets/images/website/image7.png" width="100%" class="mt-30" />
                </div>
            </div>
            <div fxLayout="row wrap" class="row reverse">
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInLeft feature img-sec">
                    <img src="assets/images/website/image8.png" width="100%" class="mt-30" />
                </div>
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInRight feature">
                    <h5>Equipment Streamlining</h5>
                    <p>It renders the details of stock quantity that helps to check the equipment availability.</p>
                </div>
            </div>
            <div fxLayout="row wrap" class="row">
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInLeft feature">
                    <h5>Administration</h5>
                    <p>The user can be created by the role of employee.On the account of the user, It assigns the
                        privileges. From this, can monitor the exact activity of user also can et the master resource
                        utilization reports.</p>
                </div>
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInRight feature img-sec">
                    <img src="assets/images/website/image9.png" width="100%" class="mt-30" />
                </div>
            </div>
            <div fxLayout="row wrap" class="row reverse">
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInLeft feature img-sec">
                    <img src="assets/images/website/image10.png" width="100%" class="mt-30" />
                </div>
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInRight feature">
                    <h5>Payment Process</h5>
                    <p>Simple mode of payments for members, and can track transactions (Paid/Unpaid). It helps to
                        stabilized the cash flow and grow member base with monthly & yearly.</p>
                </div>
            </div>
            <div fxLayout="row wrap" class="row">
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInLeft feature">
                    <h5>Graphical Dashboard</h5>
                    <p>It shows the advanced statistical report on number of members, trainers, doctors and the total
                        revenue also attendnce in the dashboard. It delivers the live data of gym.</p>
                </div>
                <div fxFlex="100" fxFlex.xl="50" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                    class="wow bounceInRight feature img-sec">
                    <img src="assets/images/website/image11.png" width="100%" class="mt-30" />
                </div>
            </div>

        </div>
    </div>

</div>
<!--Membership-->
<div class="membership">
    <div class="container">
        <div class="text-center">
            <h1 class="title">Simple, transparent pricing</h1>
            <h4 class="sub-title">No Contract, No surprise fees</h4>
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                <mat-button-toggle value="Monthly">Monthly</mat-button-toggle>
                <mat-button-toggle value="Yearly">Yearly</mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div class="row" fxLayout="row wrap">
            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100" class="pl-90 pr-45">
                <div class="br-30">
                    <div class="price-details">
                        <h2><b>Rs.5,000</b> <span>/ month</span></h2>
                        <h1 class="mt-30">Starter</h1>
                        <p>For most businesses that want to optimize web queries </p>
                        <ul>
                            <li>
                                <mat-icon>done</mat-icon>All limited links
                            </li>
                            <li>
                                <mat-icon>done</mat-icon>Own analytics platform
                            </li>
                            <li>
                                <mat-icon>done</mat-icon>Chat Support
                            </li>
                            <li>
                                <mat-icon>done</mat-icon>Optimize hashtags
                            </li>
                        </ul>
                        <button mat-flat-button>Choose Plan</button>
                    </div>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100" class="active pr-45">
                <div class="price-details">
                    <h2 style="color:white;">Rs.5,000 <span>/ month</span></h2>
                    <h3 style="color:white;" class="mt-30">Starter</h3>
                    <p style="color:white;">For most businesses that want to optimize web queries </p>
                    <ul>
                        <li>
                            <mat-icon>done</mat-icon>All limited links
                        </li>
                        <li>
                            <mat-icon>done</mat-icon>Own analytics platform
                        </li>
                        <li>
                            <mat-icon>done</mat-icon>Chat Support
                        </li>
                        <li>
                            <mat-icon>done</mat-icon>Optimize hashtags
                        </li>
                    </ul>
                    <button mat-flat-button>Choose Plan</button>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- About us -->
<div class="about-us">
    <div class="container">
        <div fxLayout="row wrap">
            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                class="py-90 wow bounceInLeft">
                <div class="about-content">
                    <h1 class="title">Manage your projects <br>from your mobile</h1>
                    <p>Download the app to manage your projects, keep track of the progress and complete all tasks
                        without
                        procastinating. Stay on track and complete on time!</p>
                    <h3>Get the App</h3>
                    <a href="">
                        <img src="assets/images/appstore.png" width="30%" />
                    </a>
                    <a href="" class="ml-10">
                        <img src="assets/images/google-play.png" width="30%" />
                    </a>
                </div>

            </div>
            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100" class="wow bounceInRight">
                <img src="assets/images/website/image13.png" width="50%" height="100%" />
                <img src="assets/images/website/image12.png" width="50%" height="100%" />

            </div>
        </div>
    </div>
</div>


<!--Testimonials-->
<div class="testimonials">
    <div class="container">
        <div class="text-center">
            <h1 class="title">Happy Customers</h1>
            <h4 class="sub-title">Feedback from these happy customers helps us</h4>
        </div>
        <div class="wrapper">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide [isAnimated]="false">
                    <div class="carousel-cell">
                        <div class="slider">
                            <div class="row">
                                <p>"We had an incredible experience working and were impressed they made such a big
                                    difference
                                    in only
                                    three
                                    weeks. Our team is so grateful for the wonderful improvements they made and their
                                    ability to
                                    get
                                    familiar with the product concept so quickly. It acted as a catalyst to take our
                                    design to
                                    the next
                                    level and get more eyes on our product.</p>

                            </div>
                            <div class="arrow-down text-center"></div>
                            <div class="userimg">
                                <div class="user-details">
                                    <img src="assets/images/face-3.jpg" />
                                    <div>
                                        <h3>Jane Cooper</h3>
                                        <h5>CEO, ABC Corporation</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="carousel-cell">
                        <div class="slider">
                            <div class="row">
                                <p>"We had an incredible experience working and were impressed they made such a big
                                    difference
                                    in only
                                    three
                                    weeks. Our team is so grateful for the wonderful improvements they made and their
                                    ability to
                                    get
                                    familiar with the product concept so quickly. It acted as a catalyst to take our
                                    design to
                                    the next
                                    level and get more eyes on our product.</p>

                            </div>
                            <div class="arrow-down text-center"></div>
                            <div class="userimg">
                                <div class="user-details">
                                    <img src="assets/images/face-3.jpg" />
                                    <div>
                                        <h3>Johnson</h3>
                                        <h5>CEO, ABC Corporation</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="carousel-cell">
                        <div class="slider">
                            <div class="row">
                                <p>"We had an incredible experience working and were impressed they made such a big
                                    difference
                                    in only
                                    three
                                    weeks. Our team is so grateful for the wonderful improvements they made and their
                                    ability to
                                    get
                                    familiar with the product concept so quickly. It acted as a catalyst to take our
                                    design to
                                    the next
                                    level and get more eyes on our product.</p>

                            </div>
                            <div class="arrow-down text-center"></div>
                            <div class="userimg">
                                <div class="user-details">
                                    <img src="assets/images/face-3.jpg" />
                                    <div>
                                        <h3>Stephen</h3>
                                        <h5>CEO, ABC Corporation</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="carousel-cell">
                        <div class="slider">
                            <div class="row">
                                <p>"We had an incredible experience working and were impressed they made such a big
                                    difference
                                    in only
                                    three
                                    weeks. Our team is so grateful for the wonderful improvements they made and their
                                    ability to
                                    get
                                    familiar with the product concept so quickly. It acted as a catalyst to take our
                                    design to
                                    the next
                                    level and get more eyes on our product.</p>

                            </div>
                            <div class="arrow-down text-center"></div>
                            <div class="userimg">
                                <div class="user-details">
                                    <img src="assets/images/face-3.jpg" />
                                    <div>
                                        <h3>John</h3>
                                        <h5>CEO, ABC Corporation</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="carousel-cell">
                        <div class="slider">
                            <div class="row">
                                <p>"We had an incredible experience working and were impressed they made such a big
                                    difference
                                    in only
                                    three
                                    weeks. Our team is so grateful for the wonderful improvements they made and their
                                    ability to
                                    get
                                    familiar with the product concept so quickly. It acted as a catalyst to take our
                                    design to
                                    the next
                                    level and get more eyes on our product.</p>

                            </div>
                            <div class="arrow-down text-center"></div>
                            <div class="userimg">
                                <div class="user-details">
                                    <img src="assets/images/face-3.jpg" />
                                    <div>
                                        <h3>Jane </h3>
                                        <h5>CEO, ABC Corporation</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>


    </div>
</div>




<!--Footer-->
<app-footer></app-footer>