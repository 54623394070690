<div class="header">
    <div class="row" fxLayout="row " fxLayoutAlign="start center">
        <div>
            <a href="#" class="logo" [routerLink]="'/home'"> <img src="assets/images/logo.png" /><span>Petal
                    Yellow</span>
            </a>
        </div>
        <div>
            <span class="toggle-btn" (click)="show = !show">
                <mat-icon>menu</mat-icon>
            </span>
            <ul class="web">
                <li><a href="#">Solutions</a></li>
                <li><a href="#">Features</a></li>
                <li><a href="#">Pricing</a></li>
                <li><a href="#">Contact us</a></li>
            </ul>

        </div>
    </div>
    <ul class="mobile" *ngIf="show">
        <li><a href="#">Solutions</a></li>
        <li><a href="#">Features</a></li>
        <li><a href="#">Pricing</a></li>
        <li><a href="#">Contact us</a></li>
    </ul>
</div>