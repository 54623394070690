<!--Footer-->
<div class="footer">
    <div class="container">
        <div class="row" fxLayout="row wrap" class="pt-60">
            <div fxFlex="100" fxFlex.gt-xs="25" fxFlex.sm="25" fxFlex.md="25" fxFlex.xs="100">
                <h3 class="heading">Company</h3>
                <ul>
                    <li><a href="#">About Us</a></li>
                    <li><a href="#">Blog</a></li>
                    <li><a href="#">Demo</a></li>
                    <li><a href="#">Contact Us</a></li>
                </ul>
            </div>
            <div fxFlex="100" fxFlex.gt-xs="25" fxFlex.sm="25" fxFlex.md="25" fxFlex.xs="100">
                <h3 class="heading">Support</h3>
                <ul>
                    <li><a href="#">Login</a></li>
                    <li><a href="#">My Account</a></li>
                    <li><a href="#">Subscribe</a></li>
                </ul>

            </div>
            <div fxFlex="100" fxFlex.gt-xs="25" fxFlex.sm="25" fxFlex.md="25" fxFlex.xs="100">
                <h3 class="heading">Legal</h3>
                <ul>
                    <li><a href="#">Cookies Policy</a></li>
                    <li><a href="#">Terms of Service</a></li>
                    <li><a href="#">Privacy Policy</a></li>
                    <li><a href="#">Law Enforcement</a></li>
                </ul>
            </div>
            <div fxFlex="100" fxFlex.gt-xs="25" fxFlex.sm="25" fxFlex.md="25" fxFlex.xs="100" class="">
                <h3 class="heading">Install App</h3>
                <a href="">
                    <img src="assets/images/appstore.png" width="50%" />
                </a><br>
                <a href="" class="">
                    <img src="assets/images/google-play.png" width="50%" />
                </a>
            </div>
        </div>
        <div class="mt-30">
            <div fxLayout="row wrap" class="row copy-right pt-15">
                <div fxFlex="100" fxFlex.gt-xs="70" fxFlex.sm="70" fxFlex.md="70" fxFlex.xs="100">
                    <h5 class="copyright">Copyright © 2021 Spark Fitness, All rights reserved</h5>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="30" fxFlex.sm="30" fxFlex.md="30" fxFlex.xs="100">
                    <ul class="d-flex-center-end">
                        <li>
                            <img src="assets/images/social-media-icons/insta.png" width="100%">
                        </li>
                        <li>
                            <img src="assets/images/social-media-icons/twitter.png" width="100%">
                        </li>
                        <li>
                            <img src="assets/images/social-media-icons/youtube.png" width="100%">
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>